import * as Yup from 'yup';

export const RegistrationUserPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required('form.register.stepper.step.registration.error.password.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{10,99}$/,
      'form.register.stepper.step.registration.error.password.match',
    ),
  passwordConfirmation: Yup.string()
    .required('form.register.stepper.step.registration.error.password_confirmation.required')
    .oneOf(
      [Yup.ref('password')],
      'form.register.stepper.step.registration.error.password_confirmation.match',
    ),
});
