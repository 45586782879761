import { IntlShape } from 'react-intl/src/types';
import * as Yup from 'yup';

import { websitePattern } from './regex';

export const EditEventStreamValidation = (intl: IntlShape) =>
  Yup.object().shape({
    streamLink: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.edit_event_stream.error.stream_link.required',
          defaultMessage: 'Please provide access link to the live stream.',
        }),
      )
      .matches(
        websitePattern,
        intl.formatMessage({
          id: 'form.edit_event_stream.error.stream_link.matches',
          defaultMessage: 'Please enter a valid URL and save your changes again. ',
        }),
      ),
  });
