import { Table, TableContainer } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { LineDivider } from '@/Components';

export interface CustomTableProps {
  tableHead?: JSX.Element;
  tableBody?: JSX.Element;
  tableFooter?: JSX.Element;
  containerClass?: string;
  tableClass?: string;
}

const useStyles = makeStyles()(() => ({
  root: {
    overflowX: 'visible',
  },
}));

export const CustomTable = (props: CustomTableProps): JSX.Element => {
  const { containerClass, tableClass, tableHead, tableBody, tableFooter } = props;
  const { classes } = useStyles();

  return (
    <TableContainer className={clsx(classes.root, containerClass ? containerClass : '')}>
      <Table aria-label='simple table' className={tableClass ? tableClass : ''}>
        {tableHead}
        <LineDivider verticalSpace={10} />
        {tableBody}
        {tableFooter}
      </Table>
    </TableContainer>
  );
};
