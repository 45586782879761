import { Box } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { useGetPartnerQuery } from '@/Queries';
import { GalleryAsset, Partner } from '@/Types';

import { GalleryAssetPreview } from '../Assets/GalleryAssetPreview';
import { useGalleryImageTileStyles } from './Gallery.styles';

type GalleryImageTileProps = {
  resourceData: GalleryAsset;
  handleRemoveImage: (value: number) => void;
  dragComponent: ReactNode;
  casPublicId: Partner['casPublicId'];
  idx: number;

  latestUploads: GalleryAsset[];
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const GalleryImageTile = ({
  handleRemoveImage,
  dragComponent,
  resourceData,
  casPublicId,
  idx,
}: GalleryImageTileProps) => {
  const { classes } = useGalleryImageTileStyles();
  const { data: partner } = useGetPartnerQuery();

  return (
    <Box
      className={clsx(classes.tileWrapper, {
        [classes.tileWrapperDisabled]: !!partner?.readonly,
        [classes.activeBorder]: idx === 0,
      })}>
      {!resourceData.assetId && idx === 0 && <Box className={classes.galleryPlaceholder} />}
      {resourceData.assetId && (
        <>
          <Box className={classes.topWrapper}>{dragComponent}</Box>
          <GalleryAssetPreview
            resourceData={resourceData}
            casPublicId={casPublicId}
            handleRemoveImage={handleRemoveImage}
            isBinIcon
          />
        </>
      )}
    </Box>
  );
};
