import { IntlShape } from 'react-intl/src/types';
import * as Yup from 'yup';

import { phonePattern, websitePattern } from './regex';

export const AddressAndContactValidation = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: 'form.address_and_contact.error.email.valid',
        defaultMessage: 'Please enter a valid email address and save your changes again. ',
      }),
    ),
    phonenumber: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.address_and_contact.error.phonenumber.required',
          defaultMessage: 'Please enter a valid phone number and save your changes again.',
        }),
      )
      .matches(
        phonePattern,
        intl.formatMessage({
          id: 'form.address_and_contact.error.phonenumber.matches',
          defaultMessage: 'Please enter a valid phone number and save your changes again.',
        }),
      ),
    website: Yup.string().matches(
      websitePattern,
      intl.formatMessage({
        id: 'form.address_and_contact.error.website.matches',
        defaultMessage: 'Please enter a valid URL and save your changes again.',
      }),
    ),
  });
