import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';

export const useDropzoneStyles = makeStyles()(() => ({
  dropzone: {
    padding: '23px 0',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: '2px',
    borderRadius: '12px',
    borderColor: FigmaDesignTokens.Grey[300],
    borderStyle: 'dashed',
    backgroundColor: FigmaDesignTokens.Grey[50],
    color: FigmaDesignTokens.Grey[300],
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  dropzoneContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropzoneHeadingTwoHeic: {
    color: FigmaDesignTokens.Red[500],
  },
  disabled: {
    opacity: 0.35,
    pointerEvents: 'none',
  },
}));

export const useUploadIndicatorStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  imageTitle: {
    fontSize: 14,
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: FigmaDesignTokens.Grey[800],
    marginLeft: '20px',
  },
  leftPart: {
    display: 'flex',
    alignItems: 'center',
  },
  rightPart: {},
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 15,
  },
  errorMessage: {
    fontSize: '12px',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: FigmaDesignTokens.Red[500],
  },
  uploadItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  uploadInfo: {
    lineHeight: '0.5',
    letterSpacing: '0.1px',
    color: FigmaDesignTokens.Grey[800],
    fontSize: 12,
  },
  removeIcon: {
    height: '24px',
    width: '24px',
    padding: '4px',
    cursor: 'pointer',
  },
  indicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));
