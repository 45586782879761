import { makeStyles } from 'tss-react/mui';

import EditIcon from '@/Static/Icons/editIcon.svg';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useGalleryImageTileStyles = makeStyles()(() => ({
  tileWrapper: {
    padding: '20px 10px',
    marginBottom: 16,
    position: 'relative',
    paddingBottom: 14,
    borderRadius: 15,
    backgroundColor: FigmaDesignTokens.White,

    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[100],
      borderRadius: 15,
    },
  },
  activeBorder: {
    borderRadius: '18px',
    border: `solid 3px ${FigmaDesignTokens.Blue[300]}`,
  },
  tileWrapperDisabled: {
    pointerEvents: 'none',
  },
  isNew: {
    position: 'absolute',
    top: -6,
    left: 18,
  },
  tileInputWrapper: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      backgroundImage: `url(${EditIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '24px',
      padding: '4px',
      width: '24px',
      right: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  input: {
    '& input': {
      fontSize: 14,
    },
  },
  wrapperRemoveBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 9,
    marginBottom: 9,
  },
  removeBoxText: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
  },
  removeBoxName: {
    fontWeight: 500,
  },
  removeBoxIconBtn: {
    width: 24,
    height: 24,
  },
  alterModalBtn: {
    marginTop: 13,
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  galleryPlaceholder: {
    width: 220,
  },
}));

export const useSortableItemStyles = makeStyles()(() => ({
  activeContainer: {
    position: 'relative',
    minHeight: 417,
  },

  mainImageLabel: {
    textTransform: 'uppercase',
    content: '"Titelbild"',
    position: 'absolute',
    display: 'block',
    padding: '0 18px 0 6px',
    top: 6,
    fontSize: '10px',
    fontWeight: 'bold',
    left: '24px',
    color: FigmaDesignTokens.Blue[400],
    background: FigmaDesignTokens.White,
    zIndex: 2,
  },
  hintBoxWrapper: {
    marginLeft: 16,
  },
  adjustHintBox: {
    marginTop: 54,
  },
  tooltipRoot: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: FigmaDesignTokens.Red[500],
    },
  },
  tooltip: {
    position: 'absolute',
    left: 78,
    backgroundColor: FigmaDesignTokens.White,
    padding: '6px',
    top: 3,
    zIndex: 3,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const useGalleryFormStyles = makeStyles()(theme => ({
  uploadSuccessMessage: {
    marginLeft: 12,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[950],
  },
  tipWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  textWrapperTip: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 20,
  },
  imageTip: {
    height: '50px',
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
  },
  headingTip: { fontWeight: 500 },
  tileListWrapper: {
    '&.MuiGrid-item': {
      padding: '16px 16px',
    },
  },
  uploadBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadBtn: {
    marginTop: 12,
    marginBottom: 12,
  },
  hintWrapper: {
    marginBottom: 20,
  },
}));
