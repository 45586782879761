import {
  Box,
  createStyles,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import prettyBytes from 'pretty-bytes';
import { withStyles } from 'tss-react/mui';

import AlertIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import Image from '@/Static/Icons/atoms-symbols-icons-media-image-large.svg';
import RemoveIcon from '@/Static/Icons/removeIcon.svg';
import { Upload } from '@/Types';

import FigmaDesignTokens from '../../design/design-tokens.json';
import { useUploadIndicatorStyles } from './Dropzone.styles';

interface UploadIndicatorProps {
  uploads: Upload[];
  handleRemoveFromList?: (idx: number) => void;
  width?: number;
  handleCancel: (managedUpload: ManagedUpload, idx: number) => void;
  removeButton?: boolean;
}

const BorderLinearProgress = withStyles(
  (props: LinearProgressProps) => <LinearProgress {...props} />,
  theme =>
    createStyles({
      root: {
        width: 250,
        height: '14px',
        borderRadius: '7px',
      },
      colorPrimary: {
        backgroundColor: FigmaDesignTokens.Grey[200],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.success.main,
      },
    }),
);

export const UploadIndicator = (props: UploadIndicatorProps): JSX.Element => {
  const { uploads, handleRemoveFromList, handleCancel, removeButton = true } = props;
  const { classes } = useUploadIndicatorStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      {uploads.map((upload, idx) => (
        <Grid item xs={12} className={classes.uploadItem} key={idx}>
          {upload.file && (
            <Box className={classes.leftPart}>
              <img alt='' src={Image} height='24px' width='24px' />
              <Typography className={classes.imageTitle} variant='body2'>
                {upload.file.name}
              </Typography>
            </Box>
          )}
          <Box className={classes.rightPart}>
            {!upload.errorMessage && (
              <>
                {upload.progress && (
                  <Box display='flex' alignItems='center'>
                    <Typography className={classes.uploadInfo} variant='body2'>
                      {upload.progress &&
                        upload.file &&
                        `${prettyBytes(upload.progress.loaded, { locale: 'de' })} von ${prettyBytes(
                          upload.progress.total,
                          { locale: 'de' },
                        )} hochgeladen`}
                      {!upload.progress &&
                        upload.file &&
                        `${prettyBytes(0, { locale: 'de' })} von ${prettyBytes(upload.file.size, {
                          locale: 'de',
                        })} hochgeladen`}
                    </Typography>
                    <img
                      alt='Löschen'
                      onClick={() => handleCancel(upload.managedUpload as ManagedUpload, idx)}
                      src={RemoveIcon}
                      className={classes.removeIcon}
                    />
                  </Box>
                )}

                <Box className={classes.indicatorWrapper}>
                  {upload.progress && (
                    <BorderLinearProgress
                      variant='determinate'
                      value={(upload.progress.loaded / upload.progress.total) * 100 || 0}
                    />
                  )}

                  {!upload.progress && <BorderLinearProgress variant='determinate' value={0} />}
                  {!upload.progress && handleRemoveFromList && removeButton && (
                    <img
                      alt='Löschen'
                      onClick={() => handleRemoveFromList(idx)}
                      src={RemoveIcon}
                      className={classes.removeIcon}
                    />
                  )}
                </Box>
              </>
            )}
            {upload.errorMessage && (
              <Box className={classes.errorWrapper}>
                <Typography className={classes.errorMessage} variant='body2'>
                  {upload.errorMessage}
                </Typography>

                <img alt='Löschen' src={AlertIcon} className={classes.removeIcon} />
              </Box>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
