import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { Category, DayOption } from '@/Types';

import { CustomizedChip } from '../../App/Shared/Form/Components/Customized/CustomizedChip';
import { useSelectedCategoriesStyles } from './Categories.styles';

type SelectedCategoriesProps = {
  selectedCategories: number[];
  categories: Category[] | DayOption[];
  handleDelete: (category: Category | DayOption) => void;
  disabled: boolean;
  name: string;
};

export const SelectedCategories = ({
  categories,
  handleDelete,
  selectedCategories,
  disabled,
  name,
}: SelectedCategoriesProps) => {
  const [resolvedSelectedCategories, setResolvedSelectedCategories] = useState<
    Category[] | DayOption[]
  >([]);
  const { classes } = useSelectedCategoriesStyles();

  useEffect(() => {
    const resolved = selectedCategories
      .map(selectedCategoryId =>
        // Typescript is acting up here, so we need to ignore the error for now
        // @ts-ignore
        categories.find(category => parseInt(category.id, 10) === selectedCategoryId),
      )
      .filter(Boolean) as Category[] | DayOption[];

    setResolvedSelectedCategories(resolved);
  }, [selectedCategories, categories]);

  return (
    <Grid container spacing={2} id={name}>
      {resolvedSelectedCategories.map((category, idx) => (
        <Grid item key={category.id || idx} className={classes.item}>
          {category && (
            <CustomizedChip
              icon={
                'iconUrlContour' in category && category.iconUrlContour ? (
                  <img alt='' className={classes.img} src={category.iconUrlContour} />
                ) : undefined
              }
              disabled={disabled}
              label={category.shortDescription}
              onDelete={!disabled ? () => handleDelete(category) : undefined}
            />
          )}
        </Grid>
      ))}
      {categories.length === 0 && (
        <Grid item className={classes.item}>
          -
        </Grid>
      )}
    </Grid>
  );
};
