import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import DownloadButton from '@/Components/DownloadButton';
import { useAppContext } from '@/Context';
import { useTableRows } from '@/Hooks/useTableRows';
import { useGetDownloadsDataQuery } from '@/Queries';
import { AssetServiceFactory } from '@/Services';
import { MarketingMaterialItem } from '@/Types';
import { getFiletypeFromFilename, getFormattedFileType } from '@/Utils/fileHelper';

import { CategoryCell } from '../TableCell.tsx/CategoryCell';
import { FileInfoCell } from '../TableCell.tsx/FileInforCell';
import { FileTypeCell } from '../TableCell.tsx/FileTypeCell';
import { NoRowsOverlay } from './NoRowsOverlay';

export const MarketingMaterialsTable = () => {
  const { formatMessage } = useIntl();
  const { dispatch } = useAppContext();

  const { data: downloadsData, isLoading: isLoadingDownloadsData } = useGetDownloadsDataQuery();

  const handleDownloadFile = async (file: MarketingMaterialItem) => {
    const assetService = new AssetServiceFactory().getInstance(dispatch);
    const blob = await assetService.DownloadFile(file.uri);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.originalFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const columns: GridColDef[] = [
    {
      field: 'information',
      headerName: formatMessage({
        id: 'view.marketing_material.table.header.information',
        defaultMessage: 'File information',
      }),
      pinnable: false,
      minWidth: 300,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<MarketingMaterialItem>) => (
        <FileInfoCell gridRenderCellParams={params} />
      ),
      valueGetter: (value, row) => row?.originalFilename || '',
    },
    {
      field: 'type',
      headerName: formatMessage({
        id: 'view.marketing_material.table.header.type',
        defaultMessage: 'Type',
      }),
      pinnable: false,
      display: 'flex',
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<MarketingMaterialItem>) => (
        <FileTypeCell gridRenderCellParams={params} />
      ),
      valueGetter: (value, row) => {
        const fileType = getFiletypeFromFilename(row?.originalFilename);

        if (!fileType) return '';
        return getFormattedFileType(fileType);
      },
    },
    {
      field: 'category',
      headerName: formatMessage({
        id: 'view.marketing_material.table.header.category',
        defaultMessage: 'Category',
      }),
      pinnable: false,
      minWidth: 200,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<MarketingMaterialItem>) => (
        <CategoryCell gridRenderCellParams={params} />
      ),
      valueGetter: (value, row) => row?.categoryName || '',
    },
    {
      field: 'languages',
      display: 'flex',
      headerName: formatMessage({
        id: 'view.marketing_material.table.header.languages',
        defaultMessage: 'Available languages',
      }),
      pinnable: false,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => (
        <DownloadButton onClick={() => handleDownloadFile(params.row)} />
      ),
    },
  ];

  const rows = useTableRows({
    uniqueIdKey: 'marketingMaterialId',
    data: downloadsData || [],
  });

  return (
    <CustomDataGrid
      columns={columns}
      autoHeight
      rows={rows}
      rowHeight={86}
      columnHeaderHeight={48}
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      hideFooter={!downloadsData || !downloadsData.length}
      pagination
      loading={isLoadingDownloadsData}
      slots={{
        noRowsOverlay: () => (
          <NoRowsOverlay
            header={formatMessage({
              id: 'view.marketing_material.table.empty_header',
              defaultMessage: 'No result for your filter selection',
            })}
            subHeader={formatMessage({
              id: 'view.marketing_material.table.empty_sub_header',
              defaultMessage:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore ipsum dolor sit amet, consetetur sadipscing elitr.',
            })}
          />
        ),
      }}
    />
  );
};
