import { Box, Grid, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextFieldProps } from 'formik-mui';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import { InfoMessage } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useIsFirstTranslation from '@/Hooks/useIsFirstTranslation';
import useReadStorage from '@/Hooks/useReadStorage';
import { getCourseLevels, getCourseTypes } from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import { DeeplService } from '@/Services';
import { ReactComponent as Arrow } from '@/Static/Icons/atoms-symbols-arrow-light.svg';
import { Course, CourseTypes, LanguageType } from '@/Types';
import { getTargetLanguage } from '@/Utils';

import { useCourseDataStyles } from './Course.styles';

type CourseDataProps = {
  deeplService: DeeplService;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

const CourseData = ({ deeplService, handlePrimaryCtaDisabled }: CourseDataProps) => {
  const { values, setFieldValue, isValid, isValidating, initialValues } =
    useFormikContext<Course>();

  const { data: partner } = useGetPartnerQuery();

  const pageLanguage = useCurrentLanguage();
  const currentLanguage =
    useReadStorage<LanguageType>('vpp_translation_content_language', 'LOCAL_STORAGE') ||
    pageLanguage;
  const targetLanguage = getTargetLanguage(currentLanguage);

  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();

  const { classes } = useCourseDataStyles();
  const { formatMessage } = useIntl();
  const levels = getCourseLevels();

  //hide onsite if "settings" does not exist or hansefitOnSiteCoursesEnabled is false
  const isOnsiteEnabled = partner
    ? partner['settings']
      ? partner.settings.onSiteCoursesEnabled
      : false
    : false;

  const courseTypes = isOnsiteEnabled
    ? getCourseTypes()
    : getCourseTypes().filter(course => course.value !== CourseTypes.ONSITE);

  const handleTranslation = useCallback(
    async (textToTranslate: string, field: string) => {
      if (!currentLanguage) {
        return;
      }

      await deeplService.handleTranslation({
        currentValue: textToTranslate,
        field,
        currentLanguage,
        setFieldValue,
        isFirstTranslation,
        setIsFirstTranslation,
        targetLanguage,
      });
    },
    [
      setIsFirstTranslation,
      setFieldValue,
      isFirstTranslation,
      deeplService,
      targetLanguage,
      currentLanguage,
    ],
  );

  useEffect(() => {
    if (values.neededAccessoires.de !== '' || values.neededAccessoires.en !== '') {
      setFieldValue('isNeededAccessoires', true);
    }
  }, [setFieldValue, values.neededAccessoires]);

  useEffect(() => {
    // remove isNeededAccessoires from the difference check
    const compInitialValues = { ...initialValues };
    const compValues = { ...values };
    delete compInitialValues.isNeededAccessoires;
    delete compValues.isNeededAccessoires;
    handlePrimaryCtaDisabled(!(!isEqual(compInitialValues, compValues) && isValid));
  }, [isValid, handlePrimaryCtaDisabled, isValidating, initialValues, values]);

  if (!partner) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <ContentContainer>
        <Grid container rowSpacing={3} columnSpacing={5}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              {values.courseId ? (
                <FormHeader title={values.courseName[currentLanguage]} />
              ) : (
                <FormHeader
                  title={formatMessage({
                    id: 'form.course.header.add_course_data',
                    defaultMessage: 'Add course dates',
                  })}
                />
              )}
            </Grid>
          </Grid>
          <FormikErrorMessage name='courseId' />
          <Grid xs={7} item container>
            <Grid container spacing={4}>
              {values.courseId && (
                <Grid item container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      component={FormikTextField}
                      placeholder='123'
                      variant='outlined'
                      disabled
                      name='courseId'
                      type='text'
                      label={formatMessage({
                        id: 'form.course.field.courseId.label',
                        defaultMessage: 'Course ID',
                      })}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field disabled={partner.readonly} name={`courseName.${currentLanguage}`}>
                    {(props: TextFieldProps) => (
                      <FormikTextField
                        {...props}
                        variant='outlined'
                        type='text'
                        label={
                          formatMessage({
                            id: 'form.course.field.courseName.label',
                            defaultMessage: 'Course name*',
                          }) + ` | ${currentLanguage?.toUpperCase() || 'N/A'}`
                        }
                        onBlur={e => {
                          handleTranslation(e.target.value, 'courseName').then(() => {
                            props.field.onBlur(e);
                          });
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    disabled={partner.readonly || values.courseId}
                    component={FormikTextField}
                    variant='outlined'
                    name='internalName'
                    type='text'
                    label={formatMessage({
                      id: 'form.course.field.internalName.label',
                      defaultMessage: 'Internal course name*',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    disabled={partner.readonly || values.courseId}
                    component={FormikTextField}
                    variant='outlined'
                    name='courseType'
                    select
                    SelectProps={{
                      IconComponent: () =>
                        !values.courseId && (
                          <Arrow className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
                        ),
                    }}
                    label={
                      formatMessage({
                        id: 'form.course.field.courseType.label',
                        defaultMessage: 'Course type',
                      }) + '*'
                    }>
                    {courseTypes.map((type, idx) => (
                      <MenuItem
                        key={idx}
                        value={type.value}
                        selected={values.courseType === type.value}>
                        {formatMessage({ id: type.id, defaultMessage: type.defaultMessage })}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field disabled={partner.readonly} name={`shortDescription.${currentLanguage}`}>
                    {(props: TextFieldProps) => (
                      <FormikTextField
                        {...props}
                        variant='outlined'
                        type='text'
                        onBlur={e => {
                          handleTranslation(
                            e.target.value.replace(/&/g, 'and'),
                            'shortDescription',
                          ).then(() => {
                            props.field.onBlur(e);
                          });
                        }}
                        placeholder={formatMessage({
                          id: 'form.course.field.shortDescription.placeholder',
                          defaultMessage: 'Free text (max. character limit)',
                        })}
                        multiline
                        rows={4}
                        label={`${formatMessage({
                          id: 'form.course.field.shortDescription.label',
                          defaultMessage: 'Course description*',
                        })} | ${currentLanguage?.toUpperCase() || 'N/A'}`}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={5} item>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InfoMessage
                  title={formatMessage({
                    id: 'form.course.hint.courseName.header',
                    defaultMessage: 'Course name & Internal course designation',
                  })}
                  description={formatMessage({
                    id: 'form.course.hint.courseName.description',
                    defaultMessage: `The course name is displayed to the Hansefit member.\nThe internal course name is only for you for a better overview and is not displayed publicly.
                      The internal course name must be unique and may not be duplicated.`,
                  })}
                  type='information'
                />
              </Grid>
              <Grid item xs={12}>
                <InfoMessage
                  title={formatMessage({
                    id: 'form.course.hint.translation.header',
                    defaultMessage: 'Online courses via Zoom',
                  })}
                  description={formatMessage({
                    id: 'form.course.hint.translation.description',
                    defaultMessage:
                      'Please note that currently only online courses that take place via Zoom can be entered via the course manager.',
                  })}
                  type='information'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={7} item container>
            <Grid container spacing={4}>
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    disabled={partner.readonly}
                    component={FormikTextField}
                    select
                    SelectProps={{
                      IconComponent: () => (
                        <Arrow className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
                      ),
                    }}
                    variant='outlined'
                    name='level'
                    label={formatMessage({
                      id: 'form.course.field.level.label',
                      defaultMessage: 'Level*',
                    })}>
                    {levels.map((type, idx) => (
                      <MenuItem key={idx} value={type.value} selected={values.level === type.value}>
                        {formatMessage({
                          id: type.id,
                          defaultMessage: type.defaultMessage,
                        })}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SubHeader
                  title={formatMessage({
                    id: 'form.course.field.neededAccessoires.subheader',
                    defaultMessage: 'Tools / equipment needed',
                  })}
                />
                <Box className={classes.switchWrapper}>
                  <Box className={classes.switchFieldAndLabel}>
                    <Field
                      disabled={partner.readonly}
                      type='checkbox'
                      component={FormikSwitch}
                      name='isNeededAccessoires'
                    />
                    <SwitchLabel
                      title={formatMessage({
                        id: 'form.course.field.isNeededAccessoires.label',
                        defaultMessage: 'Equipment needed',
                      })}
                    />
                  </Box>
                  <FormikErrorMessage name='isNeededAccessoires' />
                </Box>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    disabled={!values.isNeededAccessoires && partner.readonly}
                    name={`neededAccessoires.${currentLanguage}`}>
                    {(props: TextFieldProps) => (
                      <FormikTextField
                        {...props}
                        variant='outlined'
                        type='text'
                        onBlur={e => {
                          handleTranslation(e.target.value, 'neededAccessoires').then(() => {
                            props.field.onBlur(e);
                          });
                        }}
                        placeholder={formatMessage({
                          id: 'form.course.field.neededAccessoires.placeholder',
                          defaultMessage: 'Free text (max. character limit)',
                        })}
                        multiline
                        rows={4}
                        label={
                          formatMessage({
                            id: 'form.course.field.neededAccessoires.label',
                            defaultMessage: 'Required tools',
                          }) + ` | ${currentLanguage?.toUpperCase() || 'N/A'}`
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    disabled={partner.readonly}
                    component={FormikTextField}
                    variant='outlined'
                    name='courseDuration'
                    type='number'
                    value={values.courseDuration || 0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let value = parseInt(e.target.value, 10);
                      if (isNaN(value)) value = 0;
                      if (value < 1) value = 0;

                      setFieldValue('courseDuration', value);
                    }}
                    label={formatMessage({
                      id: 'form.course.field.courseDuration.label',
                      defaultMessage: 'Course duration (in minutes)*',
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    disabled={partner.readonly}
                    component={FormikTextField}
                    variant='outlined'
                    name='capacity'
                    type='number'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let value = parseInt(e.target.value, 10);
                      if (isNaN(value)) value = 0;
                      if (value < 1) value = 0;

                      setFieldValue('capacity', value);
                    }}
                    label={formatMessage({
                      id: 'form.course.field.capacity.label',
                      defaultMessage: 'available seats*',
                    })}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  display='flex'
                  direction='column'
                  gap={2}
                  marginTop={1}>
                  <SubHeader
                    title={formatMessage({
                      id: 'form.course.field.course_leader_info.subheader',
                      defaultMessage: 'Information about course Leader',
                    })}
                  />
                  <Field
                    disabled={partner.readonly}
                    component={FormikTextField}
                    variant='outlined'
                    name='courseInstructor'
                    type='text'
                    label={formatMessage({
                      id: 'form.course.field.courseInstructor.label',
                      defaultMessage: 'Course management Name',
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={5} item>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InfoMessage
                  title={formatMessage({
                    id: 'form.course.hint.infobox.header',
                    defaultMessage: 'Distinction: Courses & Dates',
                  })}
                  description={formatMessage({
                    id: 'form.course.hint.infobox.description',
                    defaultMessage:
                      'The course contains the framework data that apply to all dates. In the next step, you create individual dates for your course.',
                  })}
                  type='information'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
};

export default CourseData;
