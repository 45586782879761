import { Box, Button, Grid } from '@mui/material';
import clsx from 'clsx';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { BodyTextSmall, MainHeadline } from '@/Components';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { SelfRegistration } from '@/Types';
import { RegistrationUserInfoValidation } from '@/Utils';

import { RegisterPhotos } from '../../Shared/Photos/RegisterPhotos';
import { useUserFormStyles } from '../RegisterUser.styles';

export type ModifyUserDataStepProps = {
  userData: SelfRegistration;
  saveUserData: (values: SelfRegistration) => void;
  asyncErrorMessage: string;
};

const ModifyUserDataForm = ({
  saveUserData,
  userData,
  asyncErrorMessage,
}: ModifyUserDataStepProps) => {
  const { formatMessage } = useMemoedIntl();
  const { classes } = useUserFormStyles();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <Formik
      validationSchema={RegistrationUserInfoValidation}
      validateOnChange
      validateOnBlur={false}
      validate={() => {
        setSubmitDisabled(false);
      }}
      enableReinitialize
      initialValues={userData}
      onSubmit={saveUserData}>
      {formik => {
        const isFullFormDataSubmit =
          isEmpty(formik.values.email) ||
          isEmpty(formik.values.name) ||
          isEmpty(formik.values.surname);

        const isDisabledSubmit =
          submitDisabled || (!formik.isValid && isEmpty(formik.errors)) || isFullFormDataSubmit;

        return (
          <Form>
            <ConnectedFocusError />
            <Box className={classes.mainContent}>
              <Grid container gap={4} textAlign='center'>
                <Grid item width='100%'>
                  <MainHeadline
                    textContent={formatMessage({
                      id: 'form.register_user.header',
                      defaultMessage: 'User registration',
                    })}
                  />
                  <BodyTextSmall
                    textAlign='center'
                    textContent={formatMessage({
                      id: 'form.register_user.hint',
                      defaultMessage: 'Please, provide your personal data to set up your account.',
                    })}
                  />
                </Grid>
                <Grid container item width='100%' gap={2}>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='name'
                      type='text'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.name.label',
                        defaultMessage: 'First name',
                      })}
                    />
                    <FormikErrorMessage name='name' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='surname'
                      type='text'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.surname.label',
                        defaultMessage: 'Last name',
                      })}
                    />
                    <FormikErrorMessage name='surname' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='email'
                      type='email'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.email.label',
                        defaultMessage: 'E-mail address',
                      })}
                    />
                    <FormikErrorMessage name='email' />
                  </Grid>
                  {asyncErrorMessage.length > 0 && (
                    <Grid item xs={12}>
                      <BodyTextSmall
                        textAlign='center'
                        className={classes.error}
                        textContent={formatMessage({
                          id: asyncErrorMessage,
                          defaultMessage: asyncErrorMessage,
                        })}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item container xs={12} alignItems='center' direction='column'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isDisabledSubmit}
                    className={clsx(classes.mainActionBtn, classes.userBtn)}>
                    {formatMessage({
                      id: 'form.register_user.cta.next_registration_step',
                      defaultMessage: 'Next',
                    })}
                  </Button>
                </Grid>
                <RegisterPhotos />
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModifyUserDataForm;
