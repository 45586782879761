import * as Yup from 'yup';

export const InitialPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .required('form.register.stepper.step.registration.error.email.required')
    .email('form.register.stepper.step.registration.error.email.invalid')
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      'form.register.stepper.step.registration.error.email.invalid',
    ),
  temporaryPassword: Yup.string()
    .required('form.register.stepper.step.registration.error.password.required')
    .min(3),
  password: Yup.string()
    .required('form.register.stepper.step.registration.error.password.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{10,99}$/,
      'form.register.stepper.step.registration.error.password.match',
    ),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password')],
      'form.register.stepper.step.registration.error.password_confirmation.match',
    )
    .required('form.register.stepper.step.registration.error.password_confirmation.required'),
});
