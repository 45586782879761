import { Box, Checkbox, Modal, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';

import { Button } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import useMemoedIntl from '@/Hooks/useMemoedIntl';

import useModalStyles from './ModalStyles';

type GeneralModalProps = {
  open: boolean;
  title: string;
  iconSrc: string;
  content: string;
};

export const DelayModal = ({ open, title, iconSrc, content }: GeneralModalProps) => {
  const { classes } = useModalStyles();
  const { formatMessage } = useMemoedIntl();
  const { dispatch } = useAppContext();
  const [, setCookies] = useCookies(['profileModal']);

  return (
    <Modal open={open}>
      <Box className={classes.modalWrapper}>
        <Box display='flex' alignItems='center'>
          <Box mr={1.5}>
            <img alt='' src={iconSrc} />
          </Box>
          <Typography className={classes.titleModal}>{title}</Typography>
        </Box>
        <Box>
          <Typography className={classes.contentModal}>{content}</Typography>
          <Box display='flex' alignItems='center'>
            <Checkbox
              onChange={e => {
                const val = e.target.checked;

                dispatch({
                  type: DelayModalTypes.SET_NOT_SHOW_PROFILE,
                  payload: { notShowProfileModal: val },
                });

                setCookies('profileModal', val, { path: '/' });
              }}
              className={classes.customCheckbox}
            />
            <Typography className={classes.checkboxText}>
              {formatMessage({
                id: 'modal.not.show.message',
                defaultMessage: 'Diese Meldung nicht mehr anzeigen',
              })}
            </Typography>
          </Box>
        </Box>
        <Box mt={2.5}>
          <Button
            onClick={() => {
              dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: false } });
            }}>
            {formatMessage({
              id: 'view.courses_and_events.events.dialog.edit_events.cta.action',
              defaultMessage: 'Understood',
            })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DelayModal;
